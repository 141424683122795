<template>
  <div style="background: #F7F7F7;width: 100%;height: 100vh;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >
      <el-form ref="form" :model="form" :rules="rules" >
        <div style="width: 100%;padding-bottom: 20px">新增账号</div>

        <el-form-item label="角色类型" label-width="100px" prop="roleIds">
          <el-radio-group v-model="form.roleIds" @change="getTypeList">
            <el-radio label="1" name="type">超级管理员</el-radio>
            <el-radio label="2" name="type">村级</el-radio>
            <el-radio label="3" name="type">公司单位</el-radio>
            <el-radio label="4" name="type">居委</el-radio>
            <el-radio label="5" name="type">行政事业单位国有资产</el-radio>


          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.roleIds!=1&&form.roleIds!=undefined" label="具体单位" label-width="100px">
          <el-select v-model="form.manageId" placeholder="具体单位">
            <el-option
                v-for="(item, index) in manageTypeList"
                :key="index"
                :label="item.manageName"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号名称" label-width="100px" prop="nickName">
          <el-input v-model="form.nickName" ></el-input>
        </el-form-item>
        <el-form-item label="登录账号" label-width="100px" prop="userName">
          <el-input v-model="form.userName" ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" label-width="100px" prop="password">
          <el-input v-model="form.password" ></el-input>
        </el-form-item>

        <el-form-item label="账号状态" label-width="100px" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="0" name="type">启用</el-radio>
            <el-radio label="1" name="type">禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <div style="width: 100%;text-align: right;">
          <el-button type="primary" @click="onSubmit('form')">确定</el-button>
          <el-button @click="onBack">取消</el-button>
        </div>


      </el-form>
    </div>

  </div>

</template>
<script>
import {getJuEdit, getUserInfo, getUserAdd, getUserEdit, getManageId} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {
      },
      id:'',
      rules: {
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        roleIds: [{ required: true, message: "角色不能为空", trigger: "blur" }],
        status: [{ required: true, message: "状态不能为空", trigger: "blur" }],
      },
      manageType:'',
      manageTypeList:[],
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/role', query: {id: this.id}});
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = this.fileList.filter(item => item !== file);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(this.form,"id")
          let id = this.form.roleIds.split(',');
          this.form.roleIds = id;

            let data = this.form;
            if (this.id){
              getUserEdit(data).then((res) => {
                if (res.code===200){
                  this.$message({
                    type: "success",
                    message: "编辑成功!",
                  });
                  this.$router.push({ path: '/role', query: {id: this.form.manageId}});
                }
              });
            }else {
              getUserAdd(data).then((res) => {
                if (res.code===200){
                  this.$message({
                    type: "success",
                    message: "新增成功!",
                  });
                  this.$router.push({ path: '/role', query: {id: this.form.manageId}});
                }
              });
            }
        }else {
          return false;
        }
      });
    },
    initData(){
      getUserInfo( this.form.id).then((res) => {
        if (res.code===200){

          this.form=res.data.user;
          this.form.roleIds=res.data.roleIds.join(',');
          this.getTypeList(this.form.roleIds);
          // this.form.userName=res.data.user.userName;
          // this.form.nickName=res.data.user.nickName;
          // this.form.status=res.data.user.status;
          // this.form.password=res.data.user.password;

          console.log(this.form,'sssss')

        }
      });

    },
    getTypeList(e){
      if (e==1){
        return
      }
      getManageId(e-1).then((res) => {
        if (res.code===200){
          this.manageTypeList =res.rows;
          console.log(this.manageTypeList,'manageTypeList')
        }
      });
    },

    onBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    if (this.$route.query.editId){
      this.form.id = this.$route.query.editId;
      this.id = this.$route.query.editId;
      this.initData();
    }

  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}

</style>
